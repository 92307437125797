<template>
  <vp-base-layout
    page-title="입금대상목록 다운로드"
    page-default-back-link="/">
    <ion-list lines="full">
      <ion-list-header>
        <ion-label>다운로드 받으시려는 년월일을 선택 후  <br /> 발송버튼을 눌러주세요.</ion-label>
      </ion-list-header>

      <ion-item>
        <ion-select
          aria-label="Year"
          interface="popover"
          v-model="searchParams.startYear">
          <template
            v-for="(item, index) in startYear"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 년
            </ion-select-option>
          </template>
        </ion-select>
        <ion-select
          aria-label="Month"
          interface="popover"
          v-model="searchParams.startMonth">
          <template
            v-for="(item, index) in startMonth"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 월
            </ion-select-option>
          </template>
        </ion-select>
        <ion-select
          aria-label="Day"
          interface="popover"
          v-model="searchParams.startDay">
          <template
            v-for="(item, index) in startDay"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 일
            </ion-select-option>
          </template>
        </ion-select> 부터
      </ion-item>
      <ion-item>
        <ion-select
          aria-label="Year"
          interface="popover"
          v-model="searchParams.endYear">
          <template
            v-for="(item, index) in endYear"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 년
            </ion-select-option>
          </template>
        </ion-select>
        <ion-select
          aria-label="Month"
          interface="popover"
          v-model="searchParams.endMonth">
          <template
            v-for="(item, index) in endMonth"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 월
            </ion-select-option>
          </template>
        </ion-select>
        <ion-select
          aria-label="Day"
          interface="popover"
          v-model="searchParams.endDay">
          <template
            v-for="(item, index) in endDay"
            :key="index">
            <ion-select-option :value="item">
              {{ item }} 일
            </ion-select-option>
          </template>
        </ion-select> 까지
      </ion-item>
    </ion-list>

    <ion-list lines="none">
      <ion-item>
        <ion-text>
          입금 완료여부는 별도로 기록되지 않습니다.<br />
          목록 다운로드 후 직접 관리해주세요.
        </ion-text>
      </ion-item>
    </ion-list>
    <vp-button
      expand="full"
      :types="[{'fixed': fixed}]"
      @click="onClickDownload()">
      다운로드하기
    </vp-button>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { 
  IonList,
  IonLabel, 
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton
} from '@ionic/vue'
import { ref, onMounted } from 'vue'
import { useIonRouter } from '@ionic/vue'

import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { getOrders } from '@/api/purchase/purchase'
import { decrypt } from '@/utils/jsencrypt'
//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const ionRouter = useIonRouter()

const fixed = ref(true)
const searchParams = ref({
  startYear: undefined,
  startMonth: undefined,
  startDay: undefined,
  endYear: undefined,
  endMonth: undefined,
  endDay: undefined
})
const startYear = ref([])
const startMonth = ref([])
const startDay = ref([])
const endYear = ref([])
const endMonth = ref([])
const endDay = ref([])

const getDateList = () => {
  for(let i = 2023; i <= 2033; i+=1){
    startYear.value.push(i)
    endYear.value.push(i)
  }
  for(let i = 1; i <= 12; i+=1){
    startMonth.value.push((i < 10) ? '0' + i : i)
    endMonth.value.push((i < 10) ? '0' + i : i)
  }
  for(let i = 1; i <= 31; i+=1){
    startDay.value.push((i < 10) ? '0' + i : i)
    endDay.value.push((i < 10) ? '0' + i : i)
  }

  searchParams.value.startYear = startYear.value[0]
  searchParams.value.startMonth = startMonth.value[0]
  searchParams.value.startDay = startDay.value[0]
  searchParams.value.endYear = endYear.value[0]
  searchParams.value.endMonth = endMonth.value[0]
  searchParams.value.endDay = startDay.value[0]
}

// 엑셀다운로드
const onClickDownload = async() => {
  await exceldown()
}

async function exceldown(){
  const xlsx = require('xlsx')
  const wb = xlsx.utils.book_new()

  // sheet 생성 - aoa_to_sheet 방식
  const result = await getOrders()
    
  const exceHeader = {header: [['접수자이름', '접수자전화번호', '이체상태', '예금주', '은행명', '계좌번호', '검수등급1가격']]}

  const strt = ref(searchParams.value.startYear + '' + searchParams.value.startMonth + '' + searchParams.value.startDay)
  const endt = ref(searchParams.value.endYear + '' + searchParams.value.endMonth + '' + searchParams.value.endDay)   
  const filterList = ref([])

  result.data.results.forEach((obj) => {
    if(!isEmpty(obj.take_date)){
      obj.take_date = String(obj.take_date).replace(/-/gi, '').substring(0,8)
    }
    return obj
  })

  filterList.value = result.data.results.filter((obj) => (obj.take_date >= strt.value && obj.take_date <= endt.value))

  const excelData = filterList.value.map(obj => {
    return Object.assign({}, '', {
      order_nm: obj.order_nm,
      order_tel_no: obj.order_tel_no,
      acc_state_code: Number(obj.acc_state_code) === 1 ? '입금전' : '입금완료',
      acc_nm: obj.acc_nm,
      bank_nm: obj.bank_nm,
      acc_num: isEmpty(obj.acc_num) ? obj.acc_num : decrypt(obj.acc_num),
      grade1_price: obj.grade1_price
    })
  })

  const ws = xlsx.utils.json_to_sheet(excelData)
  xlsx.utils.sheet_add_aoa(ws, exceHeader.header)

  // 엑셀 파일에 sheet set(엑셀파일, 시트데이터, 시트명)
  xlsx.utils.book_append_sheet(wb, ws, 'sheet1')
  
  // 엑셀 다운로드
  const title = dayjs().format('YYYYMMDDhhmmss')
  xlsx.writeFile(wb, title + '.xlsx')
}
//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(async () => {
  getDateList()
})
</script>
